angular.module('fingerink')
    .service('newLicenseModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'signaturemodal',
                keyboard: false,
                controller: 'newLicenseModalCtrl',
                templateUrl: 'web/main/team/newLicenseModal.tpl.html',
                controllerAs: 'controller',
                resolve:{
                    invoice : orderService=>orderService.addLicensePresupuesto()
                }
            });
            return this.modalInstance;
        };
    })
    .controller('newLicenseModalCtrl', function mostrarCtrl($q, $scope, $uibModalInstance, orderService, swalService,invoice, fingerinkSession) {
        var that = this;
        that.invoice = invoice.data;
        that.cancel = () => $uibModalInstance.close();
        that.buy = () => {
            that.loading = true;
            orderService.addLicense({ name: that.name, surname: that.surname,email: that.email }).then(r => {
                that.loading = false;
                swalService.basicSwal('Correcto!', 'Se ha comprado la licencia correctamente', 'success');
                fingerinkSession.renoveToken();
                $uibModalInstance.close(r.data);
            }, e => {
                swalService.basicSwal('Error', 'Ha habido un problema al comprar la licencia', 'error');
                fingerinkSession.renoveToken();
                that.loading = false;
            });
        };
    });
